<template>
  <LazyCommonAppHeaderDiscountCompaign
    v-if="discountCampaignTopBanner && !isCheckout"
    :content="discountCampaignTopBanner.top_bar_text"
  />
  <header ref="headerEl" class="sticky top-0 z-[998] w-full bg-white">
    <CommonAppHeaderSeoNav class="visually-hidden" />
    <div ref="headerTopEl" class="relative">
      <div class="container h-16 lg:h-20">
        <div
          class="flex h-full items-center gap-x-5 justify-between lg:gap-x-12.5"
        >
          <div
            v-if="!isCheckout"
            class="flex gap-5 py-4"
            :class="{ 'flex-1': !searchOpen }"
          >
            <UiButton
              variant="clear"
              variant-type="icon"
              size="xs"
              class="lg:hidden"
              data-menu-toggle
              aria-label="side navigation"
              @click="openBurgerMenu"
            >
              <UiIcon
                aria-hidden="true"
                :name="isOpen ? 'close' : 'menu'"
                class="w-6"
              />
            </UiButton>
            <div class="hidden lg:flex items-center gap-5">
              <UiButton
                variant="clear"
                variant-type="icon"
                size="xs"
                class=""
                data-menu-toggle
                aria-label="side navigation"
                :to="localePath('/')"
              >
                <UiIcon aria-hidden="true" name="marker" class="w-6" />
              </UiButton>
              <template v-if="!hideMenu">
                <nuxt-link
                  :to="localePath('/contact-us')"
                  class="inline-flex items-center gap-1 text-xs"
                >
                  <UiIcon name="message" class="size-6" />{{ $t("contactUs") }}
                </nuxt-link>
              </template>
            </div>
          </div>
          <div
            class="flex"
            :class="{ 'flex-1': searchOpen, 'max-lg:mx-auto': isCheckout }"
          >
            <nuxt-link
              v-if="!searchOpen"
              :to="localePath('/')"
              class="max-w-full flex-shrink-0 inline-block"
              data-e2e-item="general-logo"
              @click="closeBurgerMenu"
            >
              <UiLazyImg
                class="w-30.5 lg:w-51.5"
                :alt="
                  generalStore.settings?.company_name
                    ? `${generalStore.settings?.company_name} ${$t('headerDesktopLogo')}`
                    : 'Company logo'
                "
                :src="generalStore?.settings?.desktop_logo || ''"
                :src-mobile="generalStore?.settings?.mobile_logo"
                no-lazy
              />
            </nuxt-link>
            <LazyCommonAppHeaderSearchModal
              v-if="searchOpen"
              class="flex-1"
              @close="searchOpen = false"
              @goToSearchPage="goToSearchPageHandler"
            />
          </div>

          <nuxt-link
            v-if="isCheckout"
            :to="localePath(`/cart/${generalStore.cart?.unique_id || ''}`)"
            class="ml-auto hidden items-center gap-1.5 text-xs font-light text-gray-900 lg:inline-flex"
          >
            <UiIcon name="arrow_left" class="size-5" />
            {{ $t("returnToBag") }}
          </nuxt-link>
          <div
            v-if="!hideMenu"
            class="flex items-center gap-6 justify-end"
            :class="{ 'flex-1': !searchOpen }"
          >
            <div
              class="flex items-center justify-end gap-x-3.5 py-4 lg:gap-x-6"
            >
              <UiButton
                variant="clear"
                variant-type="icon"
                size="xs"
                aria-label="global-search"
                class="hidden size-6 lg:block"
                data-e2e-cta="search"
                @click.prevent="toggleSearch"
              >
                <UiIcon aria-hidden="true" name="search" class="size-6" />
              </UiButton>
              <UiButton
                v-if="generalStore.isAuthenticated"
                variant="clear"
                variant-type="icon"
                size="xs"
                class="max-lg:hidden"
                aria-label="profile"
                :to="localePath('/profile')"
                data-e2e-cta="profile-icon"
                @click="closeBurgerMenu"
              >
                <UiIcon aria-hidden="true" name="user" class="size-6" />
              </UiButton>
              <UiButton
                v-else
                variant="clear"
                variant-type="icon"
                size="xs"
                class="max-lg:hidden"
                aria-label="login"
                :to="localePath('/sign-in')"
                data-e2e-cta="profile-icon"
                @click="goToSignIn"
              >
                <UiIcon aria-hidden="true" name="user" class="size-6" />
              </UiButton>
              <UiButton
                variant="clear"
                variant-type="icon"
                size="xs"
                class="max-lg:hidden"
                aria-label="wishlist"
                data-e2e-cta="wishlist"
                @click="openWishlist"
              >
                <UiIcon aria-hidden="true" name="heart" class="size-6" />
              </UiButton>
              <div class="relative flex">
                <UiButton
                  variant="clear"
                  variant-type="icon"
                  size="xs"
                  aria-label="cart"
                  data-e2e-cta="cart"
                  @click="openCart"
                >
                  <UiIcon aria-hidden="true" name="cart" class="size-6" />

                  <ClientOnly>
                    <div
                      v-if="cartItemsQuantity"
                      class="absolute top-0 -right-0.5 flex h-3 min-w-3 justify-center items-center rounded-full bg-black px-px"
                      data-e2e-item="cart-counter"
                    >
                      <span class="ui-text-captions font-normal text-white">{{
                        cartItemsQuantity
                      }}</span>
                    </div>
                  </ClientOnly>
                </UiButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <LazyCartDrawer />
      <LazyProfileWishlistDrawer />
    </div>
    <div v-if="!isCheckout" ref="headerNavEl">
      <LazyCommonAppHeaderNav
        class="hidden h-full flex-shrink-0 py-4 lg:block"
      />
    </div>
  </header>
  <LazyCommonAppHeaderSearchModalMobile
    v-if="!isCheckout && !isCart"
    class="lg:hidden"
    @setSearchLength="setSearchLength"
    @close="searchOpen = false"
    @goToSearchPage="goToSearchPageHandler"
  />

  <CommonAppHeaderBurgerModalLanding
    v-model="isOpen"
    :logo-src="generalStore?.settings?.desktop_logo ?? ''"
  />
</template>

<script setup lang="ts">
import type { MaybeElement } from "@vueuse/core";

const localePath = useLocalePathPolyfill();
const generalStore = useGeneralStore();
const checkoutStore = useCheckoutStore();
const route = useRoute();
const { cartItemsQuantity } = useCart();
const { goToSearchPageHandler } = useGoToSearchPage();

const searchOpen = ref(false);
const searchedData = ref<string>("");
const headerEl = ref<MaybeElement>();
const headerTopEl = ref<MaybeElement>();
const headerNavEl = ref<MaybeElement>();
const isOpen = ref(false);

const { height } = useElementSize(headerEl);
const { height: headerNavHeight } = useElementSize(headerNavEl);
const { height: headerTopHeight } = useElementSize(headerTopEl);

watch(height, () => {
  setCustomProperty("--header-height", `${height.value}px`);
  setCustomProperty(
    "--header-top-height",
    `${headerTopHeight.value ? headerTopHeight.value + 1 : 0}px`,
  );
  setCustomProperty("--header-nav-height", `${headerNavHeight.value}px`);
});

const isCheckout = computed(() => {
  return (
    route.path.includes("checkout") && !route.path.includes("checkout/success")
  );
});

const isCart = computed(() => {
  return route.path.includes("cart");
});
const hideMenu = computed(() => {
  return isCheckout.value;
});

const discountCampaignTopBanner = computed(() => {
  return generalStore.discountCampaignsData.topBanner;
});

watch(searchedData, async (val) => {
  const el = document?.querySelector("body");
  if (!el) {
    return;
  }
  window.scrollTo(0, 0);
  await nextTick();

  val ? (el.style.overflowY = "hidden") : el.style.removeProperty("overflow-y");
});

function openBurgerMenu() {
  isOpen.value = !isOpen.value;
}

function openWishlist() {
  checkoutStore.isCartOpen = false;
  closeBurgerMenu();
  generalStore.isWishlistDrawerOpen = !generalStore.isWishlistDrawerOpen;
}

function toggleSearch() {
  closeBurgerMenu();
  searchOpen.value = !searchOpen.value;
}

function setSearchLength(data: string) {
  searchedData.value = data;
}

function closeBurgerMenu() {
  isOpen.value = false;
}

function goToSignIn() {
  generalStore.isWishlistDrawerOpen = false;
  checkoutStore.isCartOpen = false;
  closeBurgerMenu();
}

function openCart() {
  generalStore.isWishlistDrawerOpen = false;
  closeBurgerMenu();
  checkoutStore.isCartOpen = !checkoutStore.isCartOpen;
}
</script>
